import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATEGORY_TYPE,
    ETK_STANDARD,
    IS_ADDITIONAL_TYPE,
    IS_NEXT_CARD,
    IS_WORKFLOW_TYPE,
    ITEM_TYPE,
    NO_ThANKS_CARD,
    REDIRECTION,
    TCPOS,
} from "@constants";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { areEqual } from "react-window";
import { Card } from "reactstrap";

import { store } from "@store";

import AdditionalSale from "@components/OrderTaking/Components/Dialogs/AdditionalSale";
import AlertDialog from "@components/OrderTaking/Components/Dialogs/AlertDialog/AlertDialog";
import NutritionalInformationDialog from "@components/OrderTaking/Components/Dialogs/NutritionalInformation/NutritionalInformationDialog";
import ProductSizeDialog from "@components/OrderTaking/Components/Dialogs/ProductSizeDialog";
import { RemoveProductConfirmation } from "@components/OrderTaking/Components/Dialogs/RemoveProductConfirmation";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import {
    addItemCommandTcPos,
    getProductTitle,
    groupWorkflowShopCard,
    orderItemsToDelete,
    removeItemCommandTcPos,
    useToggle,
} from "@components/OrderTaking/Helpers";
import {
    addToOrder,
    addToOrderworkflow,
    addWorkFlowItemShopCart,
    ClearWorkflowItem,
    minusNbrChoiceOfCurrentStep,
    removeFromOrder,
    removeFromSelectedAdditionalItems,
    removeFromWorkFlowItemShopCart,
    selectedAdditionalItems,
    SelectProduct,
    setActiveCategoryParent,
    setAdditionalItems,
    setIsCommentKeyboardActive,
    setIsModification,
    setiuudWorkflow,
    setShowToast,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { NoThanks } from "../Cart";
import {
    animatePicture,
    animatePicturePrm,
    animatePictureWithOutShopcart,
} from "./animation";
import { OutOfStock } from "./component";
import ComponentRender from "./ComponentRender";
import {
    addNbrOfChoiceStep,
    canAddItemToShopCard,
    checkWorkflow,
    getAdditionnalSaleItems,
    getItemPrice,
    haveAllergens,
} from "./hooks";
import "./index.css";

export default function CardProduct({
    product,
    currentWorkflow,
    orderItems,
    workflowData,
    orderWorkflowItemSlice,
}) {
    const dispatch = useDispatch();
    let listItemsToBeDeleted = [];

    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] =
        React.useState(false);

    const [
        isRemoveProductConfirmationDialogOpened,
        setIsRemoveProductConfirmationDialogOpened,
    ] = React.useState(false);
    const [
        isAllergenConfirmationDialogOpened,
        setIsAllergenConfirmationDialogOpened,
    ] = useToggle();

    const [
        isNutritionalInformationDialogOpen,
        setIsNutritionalInformationDialogOpen,
    ] = useToggle();

    const [isAdditionalSaleOpen, SetIsAdditionalSaleOpen] =
        React.useState(false);

    const [isProductSizeDialogOpen, setIsProductSizeDialogOpen] = useToggle();

    const { posEditor } = useSnapshot(store);
    const { isPrm, selectedSaleModeId } = useSnapshot(kioskStore);

    const { globalcard, nestedCategories, cardType, additionalSale } =
        useSelector((state) => state.orderSlice, shallowEqual);
    const {
        isShopCartDetailsActive,
        isBorderCardProductActive,
        isCommentProductActive,
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const isAllergenIconActive = haveAllergens(product);

    const productType =
        (product?.isRedirect && currentWorkflow) ||
        product.isRedirect ||
        product.type === CATEGORY_TYPE
            ? REDIRECTION
            : product?.type === BASIC_COMPOSITION
            ? BASIC_COMPOSITION
            : product?.type === NO_ThANKS_CARD
            ? NO_ThANKS_CARD
            : product?.type === IS_NEXT_CARD
            ? IS_NEXT_CARD
            : "";

    const itemPrice = getItemPrice(
        currentWorkflow,
        product,
        selectedSaleModeId,
        orderWorkflowItemSlice?.item
    );
    const isHaveOneOption =
        product?.optionsKiosk?.Options.length === 1 &&
        product?.optionsKiosk?.Options[0].listOptions.length > 0
            ? product?.optionsKiosk?.Options[0].listOptions.length === 1
                ? true
                : false
            : false;
    let initialItemOrder =
        product.type === ITEM_TYPE
            ? {
                  iuud: product.iuud,
                  isReward: false,
                  haveComment: product?.haveComment,
                  comment: "",
                  isAdditionnalProduct: product.isAdditionnalProduct || false,
                  name:
                      product?.name?.content !== undefined
                          ? product.name.content
                          : product.title,
                  designation: product.title,
                  displayTitle: getProductTitle(product?.name),
                  quantity: 1,
                  price: itemPrice.price,
                  suspendSale: product.suspendSale,
                  specialPrice: product.specialPrice,
                  priceOfItemInStep: product.priceOfItemInStep,
                  urlImage: product.image,
                  modifier: product.modifier,
                  reference: product.reference,
                  categoryParent: product.parentCategory,
                  categoryVisibility: product.categoryParent
                      ? globalcard.categories[product.categoryParent]
                            ?.KioskVisibility
                      : {},
                  categoryTitle: product.categoryTitle,
                  subcategoryTitle: product.subcategoryTitle,
                  tva: product.price?.saleModeVatRates[selectedSaleModeId]
                      ? product.price?.saleModeVatRates[selectedSaleModeId]
                      : 0,
                  iuudworkflow: "",
                  isInformationModeActivated:
                      globalcard.categories[
                          nestedCategories[nestedCategories.length - 1]
                      ].isInformationModeActivated ?? false,
                  workflow: {},
                  shopCart: {},
                  isModification: false,
                  fidelity: product.fidelity ? product.fidelity : 0,
                  totalFidelity: product.fidelity ? product.fidelity : 0,
                  selectedOptions: isHaveOneOption
                      ? [
                            getProductTitle(
                                product.optionsKiosk.Options[0].listOptions[0]
                                    .name
                            ),
                        ]
                      : [],
                  selectedOptionsUuid: isHaveOneOption
                      ? [
                            product.optionsKiosk.Options[0].iuud,
                            product.optionsKiosk.Options[0].listOptions[0].iuud,
                        ]
                      : [],
                  optionIndex: product.optionsKiosk?.Options[0]?.listOptions[0]
                      ?.optionIndex
                      ? product.optionsKiosk.Options[0].listOptions[0]
                            .optionIndex
                      : 0,
                  selectedIndexOptions: isHaveOneOption
                      ? [
                            product.optionsKiosk.Options[0].listOptions[0]
                                .optionIndex,
                        ]
                      : [],
                  date: Date.now(),
                  advancedItemPrice: product.price,
                  productVisibility: product.KioskVisibility,
                  haveWorkflow: product.haveWorkflow,
                  haveBasicComposition: product.haveBasicComposition,
                  haveAdditionalSale: product.haveAdditionalSale,
                  unity: product.unity !== undefined ? product.unity : "",
                  nutriScore: product.nutriScore,
                  designBasicComposition: product.designBasicComposition,
                  disabled: false,
                  redirection: product.redirection === true ? true : false,
                  messageBasicComposition:
                      product.messageBasicComposition || [],
                  isSubProduct: false,
              }
            : {
                  iuud: product.iuud,
                  name:
                      product?.name?.content !== undefined
                          ? product.name.content
                          : product.title,
                  quantity: 1,
              };

    const getItemQuantityInStep = () => {
        let quantity = 0;
        ExistOrderItems = [];
        if (Object.keys(orderWorkflowItemSlice.item.shopCart).length > 0) {
            let OrderItemsworkflow = currentWorkflow.workflow[
                currentWorkflow.index
            ].path.reduce((prev, key) => {
                return prev[key.name] || {};
            }, orderWorkflowItemSlice.item.shopCart);

            if (Object.keys(OrderItemsworkflow).length > 0) {
                listItemsToBeDeleted = Object.keys(OrderItemsworkflow).reduce(
                    (prev, curr) => {
                        if (OrderItemsworkflow[curr].iuud === product.iuud) {
                            quantity += OrderItemsworkflow[curr].quantity;
                            return {
                                ...prev,
                                [curr]: OrderItemsworkflow[curr],
                            };
                        }
                        return { ...prev };
                    },
                    {}
                );
                ExistOrderItems = groupWorkflowShopCard(listItemsToBeDeleted);
                listItemsToBeDeleted = orderItemsToDelete(listItemsToBeDeleted);
            }
        }

        return quantity;
    };
    const selectedAdditionnalItem = () => {
        return additionalSale?.selectedItems?.filter(
            (item) => item.iuud === product.iuud
        );
    };

    //! here after add item to bag we check if have additionnal sale or not

    if (
        product.iuud !== undefined &&
        additionalSale.parentId === product.iuud &&
        additionalSale?.listItems?.length > 0 &&
        product.modifier !== "" &&
        Boolean(isAdditionalSaleOpen) === false
    ) {
        SetIsAdditionalSaleOpen(true);
    }

    // here is items founded many times on order with different workflow but same itemIuud
    let ExistOrderItems = product.isAdditionnalProduct
        ? selectedAdditionnalItem()
        : orderItems?.filter(
              (orderItem) =>
                  !orderItem.isReward && orderItem.iuud === product.iuud
          );
    const additionalItemsQuantityChosen = () => {
        return additionalSale?.selectedItems
            ?.filter((item) => item.iuud === product.iuud)
            .reduce((prev, cur) => {
                return prev + cur.quantity;
            }, 0);
    };

    const ItemQuantity = currentWorkflow
        ? getItemQuantityInStep()
        : product.isAdditionnalProduct
        ? additionalItemsQuantityChosen()
        : ExistOrderItems?.reduce((prev, curr) => prev + curr.quantity, 0);

    const isModifier =
        product.type === ITEM_TYPE &&
        (product.haveWorkflow || product.haveBasicComposition);

    const quantity =
        ExistOrderItems.length > 0 ||
        currentWorkflow ||
        product.isAdditionnalProduct
            ? ItemQuantity
            : 0;

    const canAddItem = canAddItemToShopCard(
        quantity,
        product,
        workflowData,
        orderWorkflowItemSlice
    );
    const maxAtteint =
        currentWorkflow &&
        currentWorkflow?.workflow[currentWorkflow.index]?.NbrOfChoices ===
            currentWorkflow?.workflow[currentWorkflow.index]?.maxNbrOfChoices &&
        currentWorkflow?.workflow[currentWorkflow.index].type !==
            BASIC_COMPOSITION_TYPE;

    const isAutoPermutation =
        (orderWorkflowItemSlice.isModification ||
            orderWorkflowItemSlice.isInternalModification) &&
        product.autoPermutation;
    const menuOnModification =
        orderWorkflowItemSlice.isModification === true ||
        orderWorkflowItemSlice.isInternalModification === true;

    const opacity = isAutoPermutation
        ? "1"
        : maxAtteint || !canAddItem
        ? "0.2"
        : "1";

    function handleRemoveProductFromOrder() {
        if (currentWorkflow) {
            const data = {
                [product.title]: {
                    name: product.title,
                    type: ITEM_TYPE,
                    quantity: 1,
                    image: product.image,
                    path: [...product.path],
                    price: product.price.content,
                },
            };

            let path = [];
            data[product.title].path.forEach((elm) => {
                elm?.name ? path.push(elm.name) : path.push(elm);
            });

            if (
                quantity > 1 &&
                (ExistOrderItems.length > 1 || product.modifier)
            ) {
                handleRemoveProductConfirmation();
            } else {
                const product = listItemsToBeDeleted[0];
                dispatch(removeFromWorkFlowItemShopCart({ product, cardType }));
                dispatch(minusNbrChoiceOfCurrentStep());
            }
        } else {
            ExistOrderItems.length > 1 && product.modifier
                ? handleRemoveProductConfirmation()
                : product.isAdditionnalProduct
                ? dispatch(
                      removeFromSelectedAdditionalItems(ExistOrderItems[0])
                  )
                : TCPOS === posEditor
                ? dispatch(removeItemCommandTcPos(ExistOrderItems[0]))
                : dispatch(removeFromOrder(ExistOrderItems[0]));
        }
    }

    // ! add product to basket
    function handleAddProductToShopCart() {
        if (product.isAdditionnalProduct) {
            dispatch(selectedAdditionalItems(initialItemOrder));
            return;
        }
        if (TCPOS === posEditor) {
            dispatch(addItemCommandTcPos(initialItemOrder));
        } else {
            dispatch(addToOrder(initialItemOrder));
        }

        const clonedImage = isPrm
            ? animatePicturePrm(product.iuud)
            : isShopCartDetailsActive
            ? animatePicture(product.iuud)
            : animatePictureWithOutShopcart(product.iuud);

        let timer = setTimeout(() => {
            clonedImage?.remove();
        }, 1490);

        return () => clearTimeout(timer);
    }

    function addAdditionnalItemToOrder() {
        const additionalSale = getAdditionnalSaleItems(globalcard, product);

        if (additionalSale?.listItems?.length > 0) {
            dispatch(setAdditionalItems(additionalSale));
            SetIsAdditionalSaleOpen(true);
        }
    }

    function AddWorkFlowItemShopCartData(
        copyworkflowData,
        withSpecialPrice,
        withStepPrice
    ) {
        let title = product.title;
        let path = [...product.path];
        dispatch(setiuudWorkflow(""));
        const currentItemPriceOptions = JSON.parse(
            localStorage.getItem("currentItemPriceOptions")
        );

        let newPrice = itemPrice.price;
        let selectedOptions = [];
        let selectedOptionsUuid = [];
        let selectedIndexOptions = [];
        if (currentItemPriceOptions?.selectedOptions?.length > 0) {
            selectedOptions = currentItemPriceOptions.selectedOptions;
            selectedOptionsUuid = currentItemPriceOptions.selectedOptionsUuid;
            selectedIndexOptions = currentItemPriceOptions.selectedIndexOptions;
            newPrice = currentItemPriceOptions.newPrice;
        }

        if (Object.keys(orderWorkflowItemSlice.item.shopCart).length > 0) {
            let objectTostep = product.path.reduce((prev, key) => {
                return prev[key.name] || {};
            }, orderWorkflowItemSlice.item.shopCart);

            if (Object.keys(objectTostep).length > 0) {
                let newId = "|" + uuid();
                if (product.modifier || product.haveBasicComposition) {
                    title += newId;
                    path.pop();
                    path.push({
                        name: title,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    });
                    // eslint-disable-next-line no-undef
                    dispatch(setiuudWorkflow(newId));
                } else {
                    title += newId;
                    path.pop();
                    path.push({
                        name: title,
                        iuud: product.iuud,
                        type: product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE,
                    });
                }
            }
        }

        const data = {
            [title]: {
                name: product.title,
                designation: product.title,
                isReward: false,
                displayTitle: getProductTitle(product?.name),
                iuud: product.iuud,
                haveComment: product?.haveComment,
                comment: "",
                categoryParent: product.parentCategory,
                categoryTitle: product.categoryTitle,
                subcategoryTitle: product.subcategoryTitle,
                categoryVisibility: product.categoryParent
                    ? globalcard.categories[product.categoryParent]
                          ?.KioskVisibility
                    : {},
                suspendSale: product.suspendSale,
                reference: product.reference,
                type: ITEM_TYPE,
                quantity: 1,
                urlImage: product.image,
                path: [...path],
                price: newPrice,
                specialPrice: product.specialPrice,
                priceOfItemInStep: product.priceOfItemInStep,
                fidelity: product.fidelity ? product.fidelity : 0,
                modifier: product?.modifier,
                workflow: [...copyworkflowData],
                isModification: false,
                selectedOptions,
                selectedOptionsUuid,
                selectedIndexOptions,
                tva: product.price?.saleModeVatRates[selectedSaleModeId]
                    ? product.price?.saleModeVatRates[selectedSaleModeId]
                    : 0,
                withSpecialPrice,
                totalFidelity: product.fidelity ? product.fidelity : 0,
                withStepPrice,
                shopCart: {},
                date: Date.now(),
                priceType: withSpecialPrice ? 1 : withStepPrice ? 2 : 3,
                stepRank: product.stepRank,
                advancedItemPrice: product.price,
                stepTitle: product.stepTitle ? product.stepTitle : "",
                stepDisplayTitle: product.stepDisplayTitle
                    ? product.stepDisplayTitle
                    : "",
                productVisibility: product.KioskVisibility,
                haveWorkflow: product.haveWorkflow,
                haveBasicComposition: product.haveBasicComposition,
                unity: product.unity !== undefined ? product.unity : "",
                designBasicComposition: product.designBasicComposition,
                disabled: false,
                redirection: product.redirection === true ? true : false,
                autoPermutation: product.autoPermutation,
                messageBasicComposition: product.messageBasicComposition || [],
                isSubProduct: workflowData.length > 1 ? true : false,
            },
        };

        dispatch(addWorkFlowItemShopCart(data[title]));
        if (isModifier) {
            dispatch(SelectProduct(product));
        }
        if (
            isCommentProductActive === true &&
            product.haveComment &&
            workflowData[workflowData.length - 1]?.workflow[
                workflowData[workflowData.length - 1].index
            ].Items.length === 1
        ) {
            dispatch(
                setIsCommentKeyboardActive({
                    product: { ...data[title] },
                })
            );
        }

        // AdditionalSale;
        if (product.haveAdditionalSale) {
            addAdditionnalItemToOrder();
        }
    }

    function handleRemoveProductConfirmation() {
        setIsRemoveProductConfirmationDialogOpened(
            !isRemoveProductConfirmationDialogOpened
        );
    }

    const handleAddProductClick = () => {
        if (product.outOfStock || product.isSuspended) {
            return;
        }
        if (
            (product?.type !== BASIC_COMPOSITION &&
                currentWorkflow !== undefined &&
                currentWorkflow?.workflow[currentWorkflow.index]
                    ?.NbrOfChoices ===
                    currentWorkflow?.workflow[currentWorkflow.index]
                        .maxNbrOfChoices &&
                !isAutoPermutation) ||
            (isAutoPermutation && quantity > 0)
        ) {
            dispatch(setShowToast({ maxProduct: 0 }));
        }
        if (opacity === "0.2" || (isAutoPermutation && quantity > 0)) {
            const elm = document.getElementsByClassName("tada-animation");

            for (let index = 0; index < 4; index++) {
                elm && elm[index]?.classList.add("tada");
            }

            let timer = setTimeout(() => {
                for (let index = 0; index < 4; index++) {
                    elm && elm[index]?.classList.remove("tada");
                }
            }, 700);
            return () => clearTimeout(timer);
        } //in case workflow step  nbr of choice = max click
        if (product.type === ITEM_TYPE) {
            if (
                (product?.optionsKiosk?.showOptions &&
                    product?.optionsKiosk?.Options[0]?.listOptions.length > 0 &&
                    currentWorkflow === undefined) ||
                (cardType === ETK_STANDARD &&
                    product?.optionsKiosk?.showOptions &&
                    product?.optionsKiosk?.Options[0]?.listOptions.length > 0 &&
                    currentWorkflow &&
                    currentWorkflow?.workflow[currentWorkflow.index]
                        ?.NbrOfChoices >=
                        currentWorkflow.workflow[currentWorkflow.index]
                            .nbrOfChoicesWithspecialPrice)
                // nbr of choice >nbr max gr1
            ) {
                setIsProductSizeDialogOpen();
                return;
            }
        }
        isModifier
            ? handleWorkDialogOnCLickEvent()
            : handleProductOnClickEvent();
    };

    const handleValidateOption = () => {
        const currentItemPriceOptions =
            localStorage.getItem("currentItemPriceOptions") !== null
                ? JSON.parse(localStorage.getItem("currentItemPriceOptions"))
                : {
                      selectedOptions: [],
                      selectedOptionsUuid: [],
                      newPrice: 0,
                      tva: 0,
                      optionIndex: 0,
                      selectedIndexOptions: [],
                  };

        if (currentItemPriceOptions.selectedOptions.length > 0) {
            initialItemOrder = {
                ...initialItemOrder,
                modifier:
                    product.haveAdditionalSale === true &&
                    currentItemPriceOptions.modifier === ""
                        ? product.modifier
                        : currentItemPriceOptions.modifier,
                haveWorkflow: Boolean(currentItemPriceOptions.modifier),
                selectedOptions: currentItemPriceOptions.selectedOptions,
                selectedOptionsUuid:
                    currentItemPriceOptions.selectedOptionsUuid,
                optionIndex: currentItemPriceOptions.optionIndex,
                selectedIndexOptions:
                    currentItemPriceOptions.selectedIndexOptions,
                price: currentItemPriceOptions.newPrice,
                tva: product.price?.saleModeVatRates[selectedSaleModeId]
                    ? product.price?.saleModeVatRates[selectedSaleModeId]
                    : 0,
                urlImage: currentItemPriceOptions.optionImage,
            };
        }

        initialItemOrder.haveWorkflow ||
        initialItemOrder.haveBasicComposition ||
        currentItemPriceOptions?.modifier !== ""
            ? handleWorkDialogOnCLickEvent()
            : handleProductOnClickEvent();
    };

    function handleProductOnClickEvent() {
        if (currentWorkflow) {
            if (
                currentWorkflow.workflow[currentWorkflow.index]
                    .maxNbrOfChoices >
                    currentWorkflow.workflow[currentWorkflow.index]
                        .NbrOfChoices ||
                isAutoPermutation
            ) {
                addNbrOfChoiceStep(
                    currentWorkflow.workflow[currentWorkflow.index],
                    product,
                    AddWorkFlowItemShopCartData,
                    workflowData,
                    dispatch,
                    isAutoPermutation,
                    menuOnModification
                );
            } else {
                checkWorkflow(currentWorkflow);
            }
        } else {
            /** sous categories */
            if (
                product.type === CATEGORY_TYPE &&
                globalcard.categories[product.iuud] !== undefined
            ) {
                dispatch(setActiveCategoryParent(product.iuud));
            } else {
                handleAddProductToShopCart();
            }
        }
    }

    const handleAllergenConfirmationDialogOnCLickEvent = () => {
        setIsAllergenConfirmationDialogOpened(
            !isAllergenConfirmationDialogOpened
        );
    };

    const handleNutritionalInformationDialogOnCloseEvent = () => {
        setIsNutritionalInformationDialogOpen(
            !isNutritionalInformationDialogOpen
        );
    };

    const handleProductSizeDialogOnCloseEvent = (_event, reason) => {
        if (reason && reason == "backdropClick") return;
        setIsProductSizeDialogOpen(!isProductSizeDialogOpen);
    };

    function handleWorkDialogOnCLickEvent() {
        if (currentWorkflow) {
            addNbrOfChoiceStep(
                currentWorkflow.workflow[currentWorkflow.index],
                product,
                AddWorkFlowItemShopCartData,
                workflowData,
                dispatch,
                isAutoPermutation,
                menuOnModification
            );
        } else {
            dispatch(setWorkflowData([]));
            dispatch(SelectProduct(initialItemOrder));
            setIsWorkflowDialogOpened(true);
            dispatch(ClearWorkflowItem());
            dispatch(setIsModification(false));

            dispatch(addToOrderworkflow(initialItemOrder));
        }
    }

    const handleWorkDialogCloseEvent = () => {
        dispatch(ClearWorkflowItem());
        dispatch(SelectProduct({}));
        dispatch(setWorkflowData([]));
        setIsWorkflowDialogOpened(!isWorkflowDialogOpened);
    };

    function skipStep() {
        if (currentWorkflow !== undefined) {
            const isSuspended =
                currentWorkflow?.workflow[currentWorkflow.index].Items[0]
                    .isSuspended;
            const hasAddSale =
                currentWorkflow?.workflow[currentWorkflow.index].Items[0]
                    .haveAdditionalSale;
            const isStepSkipped =
                currentWorkflow?.workflow[currentWorkflow.index].isCreational;
            const isOutOfStockProduct =
                currentWorkflow?.workflow[currentWorkflow.index].Items[0]
                    .outOfStock;

            const hasWorkflow =
                currentWorkflow?.workflow[currentWorkflow.index].Items[0]
                    .haveWorkflow;
            if (
                hasWorkflow === false &&
                isOutOfStockProduct === false &&
                isStepSkipped === true &&
                hasAddSale === false &&
                isSuspended !== true &&
                currentWorkflow?.workflow[currentWorkflow.index]
                    ?.minNbrOfChoices === 1 &&
                currentWorkflow?.workflow[currentWorkflow.index]
                    .maxNbrOfChoices === 1 &&
                currentWorkflow?.workflow[currentWorkflow.index].NbrOfChoices <
                    currentWorkflow?.workflow[currentWorkflow.index]
                        .minNbrOfChoices &&
                currentWorkflow?.workflow[currentWorkflow.index].Items
                    .length === 1
            ) {
                handleWorkDialogOnCLickEvent();
            }
        }
    }
    React.useEffect(() => {
        if (
            currentWorkflow !== undefined &&
            orderWorkflowItemSlice.isModification === false
        ) {
            skipStep();
        }
    }, [currentWorkflow]);

    return (
        <React.Fragment>
            {isAdditionalSaleOpen && (
                <AdditionalSale
                    open={isAdditionalSaleOpen}
                    onClose={SetIsAdditionalSaleOpen}
                />
            )}
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                    isAllergenIconActive={isAllergenIconActive}
                />
            )}
            {isRemoveProductConfirmationDialogOpened && (
                <RemoveProductConfirmation
                    open={isRemoveProductConfirmationDialogOpened}
                    onClose={handleRemoveProductConfirmation}
                    items={ExistOrderItems}
                    isWorkflow={
                        currentWorkflow
                            ? IS_WORKFLOW_TYPE
                            : product.isAdditionnalProduct
                            ? IS_ADDITIONAL_TYPE
                            : ""
                    }
                />
            )}

            {isAllergenConfirmationDialogOpened && (
                <AlertDialog
                    open={isAllergenConfirmationDialogOpened}
                    onClose={handleAllergenConfirmationDialogOnCLickEvent}
                    handleProductOnClickEvent={handleAddProductClick}
                    handleProductSizeDialogOnCloseEvent={
                        handleProductSizeDialogOnCloseEvent
                    }
                    product={product}
                    isModifier={isModifier}
                    handleValidateOption={handleValidateOption}
                />
            )}
            {isNutritionalInformationDialogOpen && (
                <NutritionalInformationDialog
                    open={isNutritionalInformationDialogOpen}
                    onClose={handleNutritionalInformationDialogOnCloseEvent}
                    product={{
                        ...product,
                        price: { ...product.price, content: itemPrice.price },
                    }}
                    handleProductOnClickEvent={handleAddProductClick}
                    isAllergenIconActive={isAllergenIconActive}
                    handleAllergenConfirmationDialogOnCLickEvent={
                        handleAllergenConfirmationDialogOnCLickEvent
                    }
                    isValidateButtonActive={Boolean(workflowData?.length === 0)}
                />
            )}
            {isProductSizeDialogOpen && (
                <ProductSizeDialog
                    open={isProductSizeDialogOpen}
                    onClose={handleProductSizeDialogOnCloseEvent}
                    product={{
                        ...product,
                        price: { ...product.price, content: itemPrice.price },
                    }}
                    isModifier={isModifier}
                    handleValidateOption={handleValidateOption}
                />
            )}

            <div
                style={{
                    height: "100% !important",
                    width: "100% !important",
                }}
            >
                {productType === NO_ThANKS_CARD ||
                productType === IS_NEXT_CARD ? (
                    <NoThanks
                        productType={productType}
                        product={product}
                        workflowData={workflowData}
                    />
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "15px",
                            maxWidth: !isPrm
                                ? product.widthOfImage + "px"
                                : "auto",
                            minWidth: !isPrm
                                ? product.widthOfImage + "px"
                                : "auto",
                        }}
                    >
                        {product.outOfStock || product.isSuspended ? (
                            <OutOfStock
                                isOutOfStock={product.outOfStock}
                                isSuspended={product.isSuspended}
                                widthOfImage={product.widthOfImage}
                            />
                        ) : null}
                        <Card
                            key={product.iuud}
                            style={{
                                background:
                                    product.outOfStock &&
                                    orderWorkflowItemSlice.isModification &&
                                    quantity > 0
                                        ? "transparent"
                                        : (product.outOfStock ||
                                              product.isSuspended) &&
                                          "#A8A8A8 0% 0% no-repeat padding-box",
                                opacity:
                                    product.outOfStock &&
                                    orderWorkflowItemSlice.isModification &&
                                    quantity > 0
                                        ? "0.6"
                                        : (product.outOfStock ||
                                              product.isSuspended) &&
                                          "0.3",
                                borderRadius: "15px",
                                justifyContent: "space-between",
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                            }}
                            className={`${isPrm && "pmr-grid"} ${
                                productType === BASIC_COMPOSITION &&
                                product.basicCompositionActive &&
                                "basic_compositionActive"
                            } ${
                                productType === BASIC_COMPOSITION &&
                                !product.basicCompositionActive &&
                                "basic_compositionInactive"
                            } ${
                                !isBorderCardProductActive &&
                                "border-card-product"
                            }`}
                        >
                            <ComponentRender
                                product={{
                                    ...product,
                                    price: {
                                        ...product.price,
                                        content: itemPrice.price,
                                    },
                                }}
                                productType={productType}
                                isAllergenIconActive={isAllergenIconActive}
                                setIsAllergenConfirmationDialogOpened={
                                    setIsAllergenConfirmationDialogOpened
                                }
                                handleAddProductClick={handleAddProductClick}
                                handleProductSizeDialogOnCloseEvent={
                                    handleProductSizeDialogOnCloseEvent
                                }
                                handleRemoveProductFromOrder={
                                    handleRemoveProductFromOrder
                                }
                                opacity={opacity}
                                quantity={quantity}
                                handleNutritionalInformationDialogOnCloseEvent={
                                    handleNutritionalInformationDialogOnCloseEvent
                                }
                            />
                        </Card>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export const MemoizedCardProduct = React.memo(CardProduct, areEqual);
CardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    currentWorkflow: PropTypes.object,
    orderItems: PropTypes.array,
    workflowData: PropTypes.array,
    orderWorkflowItemSlice: PropTypes.object,
};
