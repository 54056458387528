import {
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATEGORY_TYPE,
    ITEM_TYPE,
} from "@constants";
import { produce } from "immer";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { Nutriscore } from "@components/OrderTaking/common/Nutriscore";
import NutritionalInformationDialog from "@components/OrderTaking/Components/Dialogs/NutritionalInformation/NutritionalInformationDialog";
import ClosecircleIcon from "@components/OrderTaking/Components/Icons/ClosecircleIcon";
import { InfoIcon } from "@components/OrderTaking/Components/Icons/InfoIcon";
import {
    cancelModifyProduct,
    getProductTitle,
} from "@components/OrderTaking/Helpers";
import {
    addWorkFlowItemShopCart,
    deleteBasicCompositionFromShopCart,
    deleteWorkFlowStepShopCart,
    removeLastWorkflow,
    resetNbrOfChoice,
    restoreItemComposition,
    SelectProduct,
    setAdditionalItems,
    setIsInternalModification,
    setIsModification,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { WorkflowStepper } from "./WorkflowStepper";

function WorkflowHeader({ onClose }) {
    const dispatch = useDispatch();

    const styles = { height: "70%", width: "80%" };
    const { isPrm } = useSnapshot(kioskStore);
    const { globalcard } = useSelector((state) => state.orderSlice);
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);
    const menuProduct = useSelector((state) => state.ProductSlice.product);

    const isModification = useSelector(
        (state) => state.orderWorkflowItemSlice.isModification,
        shallowEqual
    );
    const {
        isHeaderColorActive,
        isClosingButtonActive,
        closingButtonContent,
        isProductInfoIconActive,
        productInfoIconId,
        isNutriscoreInCompositeProductActive,
    } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );

    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const currentWorkflow = workflowData[workflowData.length - 1];

    const [
        isNutritionalInformationDialogOpen,
        setIsNutritionalInformationDialogOpen,
    ] = React.useState();
    const handleNutritionalInformationDialogOnCloseEvent = () => {
        setIsNutritionalInformationDialogOpen(
            !isNutritionalInformationDialogOpen
        );
    };
    const OnClickClose = () => {
        const copyWorkflow = produce(
            workflowData,
            (draftWorkflowData) => draftWorkflowData
        );
        let workflow = [...workflowData];
        workflow && workflow.pop();

        let oldComposition = {};

        if (isModification || orderWorkflowItemSlice.isInternalModification) {
            let path =
                workflow.length > 0
                    ? workflow[workflow.length - 1]?.workflow[
                          workflow[workflow.length - 1].index
                      ].path
                    : currentWorkflow?.workflow[currentWorkflow.index].path;

            let newpath = path.map((elm) => {
                return elm?.name !== undefined ? elm.name : elm;
            });

            let stepPath = newpath;

            oldComposition = cancelModifyProduct(
                newpath,
                orderWorkflowItemSlice.productBeforeUpdate
            );

            if (
                Object.keys(oldComposition).length === 0 &&
                workflow?.length > 0
            ) {
                dispatch(resetNbrOfChoice());
                dispatch(restoreItemComposition({ oldComposition, stepPath }));
            }
            if (Object.keys(oldComposition).length > 0) {
                dispatch(restoreItemComposition({ oldComposition, stepPath }));
            }
        }
        if (workflow?.length === 0) {
            dispatch(setIsModification(false));
            onClose();
        } else {
            let title =
                copyWorkflow[copyWorkflow.length - 1].workflow[
                    copyWorkflow[copyWorkflow.length - 1].index
                ].title;

            const data = {
                [title]: {
                    path: [
                        ...copyWorkflow[copyWorkflow.length - 1].workflow[
                            copyWorkflow[copyWorkflow.length - 1].index
                        ].path,
                    ],
                },
            };
            if (
                !isModification &&
                !orderWorkflowItemSlice.isInternalModification
            ) {
                dispatch(deleteWorkFlowStepShopCart(data[title]));

                if (menuProduct.haveAdditionalSale) {
                    dispatch(
                        setAdditionalItems({
                            name: "",
                            listItems: [],
                            selectedItems: [],
                        })
                    );
                }
            }

            isModification &&
                orderWorkflowItemSlice.isInternalModification &&
                copyWorkflow.length <= 1 &&
                dispatch(setIsModification(false));

            let isModif =
                isModification || orderWorkflowItemSlice.isInternalModification;
            dispatch(
                removeLastWorkflow({
                    isModif,
                    isCancelEditProduct:
                        Object.keys(oldComposition).length === 0,
                })
            );
            dispatch(SelectProduct({}));
            // dispatch(setWorkflowData(workflow));
        }
    };

    const imageSrc = React.useMemo(() => {
        return getImageContentById(closingButtonContent);
    }, []);
    const InfoImageSrc = React.useMemo(() => {
        return getImageContentById(productInfoIconId);
    }, []);

    const options = [];
    const product = {
        ...globalcard?.items?.[
            currentWorkflow?.workflow[currentWorkflow.index]?.iuudproduct
        ],
        selectedOptions: options,
    };

    product.type = product.isRedirect ? CATEGORY_TYPE : ITEM_TYPE;
    function NextStep(workflow) {
        if (workflow.step > workflow.index) {
            addIndexstep(workflow);
        } else {
            const copyworkflowData = JSON.parse(JSON.stringify(workflowData));
            copyworkflowData.splice(copyworkflowData.length - 1, 1);
            const copyDataworkflow = [...copyworkflowData];
            let index = copyDataworkflow.length;
            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = copyDataworkflow[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    dispatch(setWorkflowData(copyworkflowData));

                    return;
                } else if (workflow.step > workflow.index) {
                    addIndexstep(workflow);
                    return;
                } else {
                    index -= 1;
                    copyDataworkflow.splice(workflowIndex, 1);
                }
            }
            // ** ici on efface workflow
            if (copyDataworkflow.length === 0) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct({}));
            }
        }
    }

    function AddorderBasicCompositionWorkflow() {
        let listProduct =
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1]?.index
            ].Items;
        let add_basic_composition = false;

        const path = [
            {
                name: BASIC_COMPOSITION_TYPE,
                iuud: BASIC_COMPOSITION_TYPE,
                type: BASIC_COMPOSITION_TYPE,
            },
        ];
        dispatch(
            deleteBasicCompositionFromShopCart({
                type: BASIC_COMPOSITION_TYPE,
                urlImage: orderWorkflowItemSlice.item.urlImage,
                path,
            })
        );

        const canAddBasicCompositionShopCart = listProduct.some(
            (prod) => prod.basicCompositionActive === false
        );

        if (canAddBasicCompositionShopCart) {
            if (!add_basic_composition) {
                const path = [
                    {
                        name: BASIC_COMPOSITION_TYPE,
                        iuud: BASIC_COMPOSITION_TYPE,
                        type: BASIC_COMPOSITION_TYPE,
                    },
                ];

                let data = {
                    [BASIC_COMPOSITION_TYPE]: {
                        type: BASIC_COMPOSITION_TYPE,
                        urlImage: orderWorkflowItemSlice.item.urlImage,
                        path,
                    },
                };

                dispatch(addWorkFlowItemShopCart(data[BASIC_COMPOSITION_TYPE]));
                add_basic_composition = true;
            }
        }
        listProduct.forEach((product) => {
            if (!product.basicCompositionActive) {
                let data = {
                    [product.title]: {
                        name: "SANS " + product.title,
                        urlImage: product.image,
                        type: ITEM_TYPE,
                        quantity: 1,
                        path: [...product.path],
                        categoryParent: product.categoryParent,
                        categoryTitle:
                            globalcard.categories[product.categoryParent].title,
                        reference: product.reference,
                        iuud: product.iuud,
                        price: product.price.content,
                        modifier: product?.modifier,
                        fidelity: product.fidelity,
                        workflow: [...workflowData],
                    },
                };

                dispatch(addWorkFlowItemShopCart(data[product.title]));
            }
        });
    }
    function addIndexstep() {
        dispatch(setIsInternalModification(true));
        dispatch(setNextWorkflowStep());
    }
    function NextOnClickEvent() {
        if (
            currentWorkflow.workflow[currentWorkflow.index]?.NbrOfChoices <
            currentWorkflow.workflow[currentWorkflow.index]?.minNbrOfChoices
        )
            return;
        if (currentWorkflow) {
            if (
                currentWorkflow.workflow[currentWorkflow.index].type ===
                BASIC_COMPOSITION
            ) {
                AddorderBasicCompositionWorkflow();
            }
            if (currentWorkflow.step > currentWorkflow.index) {
                addIndexstep(currentWorkflow);
            } else {
                NextStep(currentWorkflow);
            }
        }
    }
    function PreviousOnClickEvent() {
        if (currentWorkflow.index > 0) {
            dispatch(setIsInternalModification(true));
            dispatch(setPreviousWorkflowStep());
        }
    }
    const title = currentWorkflow
        ? getProductTitle(
              globalcard?.items?.[
                  currentWorkflow?.workflow[currentWorkflow.index]?.iuudproduct
              ]?.name
          )
        : "";

    const menuItem = {
        ...product,
        price: { ...product.price, content: orderWorkflowItemSlice.item.price },
    };
    return (
        <>
            {isNutritionalInformationDialogOpen && (
                <NutritionalInformationDialog
                    open={isNutritionalInformationDialogOpen}
                    onClose={handleNutritionalInformationDialogOnCloseEvent}
                    product={menuItem}
                    isValidateButtonActive={Boolean(workflowData?.length === 0)}
                />
            )}
            <div
                className={[`${isPrm ? "h-auto" : "pt-3"} workflow-header`]}
                style={{
                    background: isHeaderColorActive
                        ? `transparent linear-gradient(180deg, #ffffff 0%, var(--global-color) 200%) 0% 0% no-repeat padding-box`
                        : "",
                }}
            >
                <div className="d-flex justify-content-around p-2 w-100">
                    <div
                        className=" d-flex justify-content-center align-items-center workflow-title w-100"
                        style={{ paddingLeft: isPrm ? "10rem" : "10rem" }}
                    >
                        <div className="d-flex flex-column align-items-center">
                            <span className="workflow-product-title preview-text">
                                {title}
                            </span>
                            {product?.selectedOptions?.length > 0 && (
                                <div className="info-options-product">
                                    {product?.selectedOptions.join(",")}
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="d-flex justify-content-end h-100"
                        style={{ width: "30%" }}
                    >
                        {!isPrm &&
                        isNutriscoreInCompositeProductActive &&
                        product.nutriScore !== undefined &&
                        Object.keys(product.nutriScore).length > 0 ? (
                            <div className="d-flex justify-content-center align-items-center h-100 w-30">
                                <Nutriscore
                                    nutriscoreType={product?.nutriScore.label}
                                    styles={styles}
                                />
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center h-100 w-30"></div>
                        )}
                        {!isPrm && isProductInfoIconActive ? (
                            <div
                                className="pt-2 d-flex align-items-start justify-content-end  h-100 w-40"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    !product.outOfStock &&
                                        handleNutritionalInformationDialogOnCloseEvent();
                                }}
                            >
                                {InfoImageSrc !== "" ? (
                                    <div
                                        style={{
                                            backgroundImage: `url(${InfoImageSrc})`,
                                            backgroundSize: "100% 100%",
                                            height: "80%",
                                            width: "100%",
                                            borderRadius: "100%",
                                        }}
                                    ></div>
                                ) : (
                                    <InfoIcon width={45} height={45} />
                                )}
                            </div>
                        ) : null}
                        {!isPrm && isClosingButtonActive ? (
                            <div
                                className={[
                                    `${
                                        workflowData.length > 1
                                            ? ""
                                            : "pt-1 d-flex align-items-start justify-content-end h-100 w-40"
                                    } `,
                                ]}
                                onClick={OnClickClose}
                            >
                                {imageSrc !== "" ? (
                                    <img
                                        style={{
                                            height: "80%",
                                            width: "100%",
                                            borderRadius: "100%",
                                        }}
                                        src={imageSrc}
                                    />
                                ) : (
                                    <ClosecircleIcon />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <WorkflowStepper
                    currentWorkflow={currentWorkflow?.workflow}
                    activestep={currentWorkflow?.index}
                    NextOnClickEvent={NextOnClickEvent}
                    PreviousOnClickEvent={PreviousOnClickEvent}
                />
            </div>
        </>
    );
}

export default WorkflowHeader;
WorkflowHeader.propTypes = {
    onClose: PropTypes.func,
};
